import React, { useRef, useMemo, useEffect, useState } from "react";
import Header from "../../../users/ais/chat/Header";
import AiMessage from "../../../users/ais/chat/messages/Ai";
import PromptSettingForm from "../../../users/ais/chat/PromptSettingForm";
import { Ai } from "../../../../interfaces/ai";
import { AiForm } from "../../../../interfaces/ai_form";
import { createConsumer } from "@rails/actioncable";
import { ToastContainer } from "react-toastify";

type Props = {
  ai: Ai;
  aiForms: AiForm[];
  cableEndpoint: string;
  userToken: string;
};

const UsersAisShow: React.FC<Props> = (props) => {
  const [formData, setFormData] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [message, setMessage] = useState("");
  const [streaming, setStreaming] = useState(false);

  const messageBottomRef = useRef(null);

  const generateRandomString = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const cancelStreaming = () => {
    setStreaming(false);
    subscription.unsubscribe();
  };

  const asystMessage = () => {
    if (props.ai.assistMessage == null) {
      return "以下の情報を入れてください";
    } else {
      return props.ai.assistMessage;
    }
  };

  const cable = useMemo(() => createConsumer(props.cableEndpoint), []);
  const onPromptSettingFormSubmit = async (data) => {
    setStreaming(true);
    setFormData(data);
    // ここでランダムの文字列生成
    const token = generateRandomString(20);
    const sub = cable.subscriptions.create(
      { channel: "PrivateChatChannel", token: token },

      {
        connected: () => {
          sub.perform("stream_gpt", {
            ai_slug: props.ai.slug,
            form_data: data,
            token: token,
            user_token: props.userToken,
          });
        },
        received: (res) => {
          if (res.error) {
            alert(res.error);
            setMessage("申し訳ございません、問題が発生しました");
          } else if (res.status) {
            if (res.status == "finish") {
              setStreaming(false);
            }
          } else {
            if (res?.data != "") {
              setMessage((prev) => prev + res?.data);
            }
          }
        },
      }
    );
    setSubscription(sub);
    setMessage("");
  };
  return (
    <>
      <div className="flex-1 justify-between flex flex-col">
        <Header ai={props.ai} />
        <div className="grid lg:grid-cols-2 lg:grid-cols-2 sm:grid-cols-1">
          <div className="p-4 pt-10">
            <AiMessage
              imageUrl={props.ai.image.thumb.url}
              message={asystMessage()}
            />
            <div className="mt-10">
              <PromptSettingForm
                submit={(data) => onPromptSettingFormSubmit(data)}
                aiForms={props.aiForms}
                streaming={streaming}
                cancelStreaming={cancelStreaming}
              />
            </div>
          </div>
          <div className="p-4">
            <div className="flex flex-col space-y-6 py-5 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
              {message != "" && (
                <AiMessage
                  imageUrl={props.ai.image.thumb.url}
                  message={message}
                  showCounter={true}
                  showAction={true}
                />
              )}
              <div ref={messageBottomRef}></div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default UsersAisShow;
