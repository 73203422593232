import React, { useMemo, useEffect, useState } from "react"
import { createConsumer } from "@rails/actioncable"

import { AiForm } from "../../../../interfaces/ai_form"
import { Ai } from "../../../../interfaces/ai"

type Props = {
  aiForms: AiForm[]
  ai: Ai
  cableEndpoint: string
}
import FormRow from "../../../admins/ais/form/FormRow"
import { adminBulkPostAiForms } from "../../../../libs/api/admins/ai_form"
import { adminUpdatePrompt } from "../../../../libs/api/admins/ai"
import { ToastContainer, toast } from "react-toastify"
import PromptSettingForm from "../../../users/ais/chat/PromptSettingForm"
import AiMessage from "../../../users/ais/chat/messages/Ai"
import Prompt from "../../../admins/ais/form/Prompt"

const AdminsAisForm: React.FC<Props> = (props) => {
  const [ai, setAi] = useState<Ai>(props.ai)
  const [aiForms, setAiForms] = useState<AiForm[]>(props.aiForms)
  const [deletedIds, setDeletedIds] = useState<string[]>([])
  const [streaming, setStreaming] = useState(false)

  const [subscription, setSubscription] = useState(null)
  const [message, setMessage] = useState("")

  const cable = useMemo(() => createConsumer(props.cableEndpoint), [])

  useEffect(() => {
    if (aiForms.length == 0) {
      addAiForms()
    }
  }, [props.aiForms])

  useEffect(() => { }, [cable])

  const newAiForm: AiForm = {
    id: "",
    kind: `input_text`,
    inputName: `name${aiForms.length}`,
    label: "",
    options: [{ name: "", value: "" }],
    helpText: "",
    col: "6",
  }

  const addAiForms = () => {
    setAiForms([...aiForms, newAiForm])
  }

  const removeAiForm = (aiForm: AiForm, i: number) => {
    setAiForms((prevForms) => {
      return prevForms.filter((prevForm: AiForm, j: number) => {
        if (i != j) {
          return prevForm
        }
      })
    })
    setDeletedIds([...deletedIds, aiForm.id])
  }

  const updateAiForm = (aiForm: AiForm, i: number) => {
    setAiForms((prevForms) => {
      return prevForms.map((prevForm: AiForm, j: number) => {
        if (i == j) {
          return aiForm
        } else {
          return prevForm
        }
      })
    })
  }

  const submit = async () => {
    let { data } = await adminBulkPostAiForms({
      aiForms: aiForms,
      aiId: props.ai.id,
      deletedIds: deletedIds,
    })
    setAiForms(data)
    toastSuccess("更新しました")
  }

  const toastSuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  }

  const generateRandomString = (length) => {
    var result = ""
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

    for (var i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      )
    }

    return result
  }

  const onSubmitPromptSettingForm = async (data: any) => {
    setStreaming(true)
    // ここでランダムの文字列生成
    const token = generateRandomString(20)
    const sub = cable.subscriptions.create(
      { channel: "PrivateChatChannel", token: token },

      {
        connected: () => {
          sub.perform("stream_gpt", {
            ai_slug: props.ai.slug,
            form_data: data,
            token: token,
          })
        },
        received: (res) => {
          if (res.error) {
            alert(res.error)
            setMessage("申し訳ございません、問題が発生しました")
          } else if (res.status) {
            if (res.status == "finish") {
              setStreaming(false)
            }
          } else {
            if (res?.data != "") {
              setMessage((prev) => prev + res?.data)
            }
          }
        },
      }
    )
    setSubscription(sub)
    setMessage("")
  }

  const setPropmt = (v: string) => {
    setAi({ ...props.ai, prompt: v })
  }

  const updatePrompt = async () => {
    await adminUpdatePrompt(ai.slug, ai.prompt)
    toastSuccess("プロンプトを更新しました")
  }

  const cancelStreaming = () => {
    setStreaming(false)
    subscription.unsubscribe()
  }

  const moveUp = index => {
    console.log("moveUp", index);
    if (index === 0) return;
    const newData = [...aiForms];
    console.log("newData", newData);
    [newData[index - 1], newData[index]] = [newData[index], newData[index - 1]];
    console.log("newData", newData);
    setAiForms(newData);
  };

  const moveDown = index => {
    if (index === aiForms.length - 1) return;
    const newData = [...aiForms];
    [newData[index + 1], newData[index]] = [newData[index], newData[index + 1]];
    setAiForms(newData);
  };

  return (
    <>
      <div className="shadow sm:overflow-hidden sm:rounded-md border">
        <div className="space-y-10 bg-white px-4 py-5 sm:p-6">
          {aiForms.map((aiForm, i) => {
            return (
              <div key={`ai-form-${aiForm.id}`} className="">
                <FormRow
                  aiForm={aiForm}
                  updateAiForm={(aiForm) => updateAiForm(aiForm, i)}
                  removeAiForm={() => removeAiForm(aiForm, i)}
                  index={i}
                  length={aiForms.length}
                  moveUp={moveUp}
                  moveDown={moveDown}
                />
              </div>
            )
          })}
          <div className="flex justify-center mt-5">
            <button
              type="button"
              className="rounded-md bg-white py-2 px-3 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={addAiForms}
            >
              + 追加
            </button>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
          <button
            type="submit"
            className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            onClick={submit}
          >
            保存する
          </button>
        </div>
      </div>
      <div className="py-3 mt-5">
        <h2 className="text-xl font-bold">Prompt</h2>
      </div>
      <div className="shadow sm:overflow-hidden sm:roudned-md border">
        <div className="p-10 pt-8">
          <Prompt ai={ai} setPropmt={(v) => setPropmt(v)} />
        </div>
        <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
          <button
            type="submit"
            className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            onClick={updatePrompt}
          >
            保存する
          </button>
        </div>
      </div>

      <div className="py-3 mt-5">
        <h2 className="text-xl font-bold">Preview</h2>
      </div>
      <div className="shadow sm:overflow-hidden sm:roudned-md border">
        <PromptSettingForm
          submit={(datas) => onSubmitPromptSettingForm(datas)}
          aiForms={aiForms}
          streaming={streaming}
          cancelStreaming={cancelStreaming}
        />
      </div>
      {message != "" && (
        <>
          <div className="py-3 mt-5">
            <h2 className="text-xl font-bold">出力結果</h2>
          </div>
          <div className="shadow sm:overflow-hidden sm:roudned-md border p-5 pb-10">
            <AiMessage message={message} imageUrl={props.ai.image.thumb.url} />
          </div>
        </>
      )}
      <ToastContainer />
    </>
  )
}
export default AdminsAisForm
