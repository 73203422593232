import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
type Props = {
  imageUrl: string;
  message: string;
  showCounter?: boolean;
  showAction?: boolean;
};
import { toast } from "react-toastify";
const UserAiChatMessgesAi: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);

  const copyClipboard = () => {
    console.log("copied");
    navigator.clipboard.writeText(props.message);
    toast.success("コピーしました");
  };

  return (
    <>
      <div className="chat-message">
        <div className="flex items-start">
          <div className="flex flex-col space-y-2 text-md mx-2 order-2 items-start w-full">
            <div className="-mb-4 w-full">
              {props.showAction && (
                <div className="w-full flex items-center justify-end pr-2 pb-2 text-gray-400">
                  <button
                    data-tooltip-target="tooltip-copy-clipboard"
                    className="focus:outline-none"
                    onClick={() => copyClipboard()}
                  >
                    <i className="fa-regular fa-copy cursor-pointer"></i>
                  </button>
                </div>
              )}
              <span className="px-4 py-2 rounded-lg w-full inline-block rounded-tl-none bg-gray-100 text-gray-800 whitespace-pre-wrap break-words">
                {props.message}
                {loading && (
                  <div className="w-16">
                    <ReactLoading
                      type={`balls`}
                      color={`#8dabd8`}
                      height={"10%"}
                      width={"30%"}
                    />
                  </div>
                )}
              </span>
              {props.showCounter && (
                <div className="text-sm text-gray-500 dark:text-gray-400 mt-2">
                  文字数: {props.message.length}
                </div>
              )}
            </div>
          </div>
          <img
            src={props.imageUrl}
            alt=""
            className="w-10 h-10 rounded-full order-1 -mt-2"
          />
        </div>
      </div>
    </>
  );
};

export default UserAiChatMessgesAi;
