import React from "react";
import { AiForm } from "../../../../../interfaces/ai_form";
import { UseFormRegister } from "react-hook-form";
type Props = {
  aiForm: AiForm;
  register: UseFormRegister<any>;
  errors: {
    [x: string]: any;
  };
};
const TextArea: React.FC<Props> = (props) => {
  if (props.aiForm.inputName == "") {
    return;
  }
  return (
    <>
      <div className={`lg:col-span-${props.aiForm.col} md:col-span-12`}>
        <label
          htmlFor="street-address"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {props.aiForm.label}
        </label>
        <textarea
          name={props.aiForm.inputName}
          id={props.aiForm.inputName}
          {...props.register(props.aiForm.inputName, { required: true })}
          className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 "
        />
        <p className="mt-1 text-xs text-gray-500">{props.aiForm.helpText}</p>
        {props.errors[`${props.aiForm.inputName}`]?.type === "required" && (
          <p className="font-semibold text-sm text-red-500">入力してください</p>
        )}
      </div>
    </>
  );
};

export default TextArea;
