import React, { useState, useEffect } from "react";
import { Ai } from "../../../../interfaces/ai";
import { AiForm } from "../../../../interfaces/ai_form";
import { MainCategory } from "../../../../interfaces/main_category";
import { adminsGetSubCategories } from "../../../../libs/api/admins/sub_category";
import { adminsGetLeafCategories } from "../../../../libs/api/admins/leaf_category";
import { adminCreateAi, adminUpdateAi } from "../../../../libs/api/admins/ai";
import { ToastContainer, toast } from "react-toastify";
import { useForm, useFieldArray } from "react-hook-form";

type Props = {
  ai: Ai;
  aiForms: AiForm[];
  mainCategories: MainCategory[];
};

const AdminsAisEdit: React.FC<Props> = (props) => {
  const [subCategories, setSubCategories] = useState([]);
  const [leafCategories, setLeafCategories] = useState([]);
  const [editImage, setEditImage] = useState(props.ai.image?.url == null);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: props.ai.name,
      intro: props.ai.intro,
      image: "",
      mainCategorySlug: props.ai.mainCategorySlug,
      subCategorySlug: props.ai.subCategorySlug,
      leafCategorySlug: props.ai.leafCategorySlug,
      userMessage: props.ai.userMessage,
      maxTokens: props.ai.maxTokens,
      temperature: props.ai.temperature,
      assistMessage: props.ai.assistMessage,
      prompt: props.ai.prompt,
      lastSystemMessage: props.ai.lastSystemMessage,
      orderIdx: props.ai.orderIdx,
      status: props.ai.status,
      slug: props.ai.slug,
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: `lastSystemMessage`,
    control: control,
  });
  useEffect(() => {
    if (fields.length == 0) {
      append("");
    }
  }, [props.ai.lastSystemMessage]);

  useEffect(() => {
    if (props.ai.mainCategorySlug != null) {
      adminsGetSubCategories({
        mainCategorySlug: props.ai.mainCategorySlug,
      }).then(({ data }) => {
        setSubCategories(data);
      });
    }
  }, [props.ai.mainCategorySlug]);

  useEffect(() => {
    if (props.ai.subCategorySlug != null) {
      adminsGetLeafCategories({
        subCategorySlug: props.ai.subCategorySlug,
      }).then(({ data }) => {
        setLeafCategories(data);
      });
    }
  }, [props.ai.subCategorySlug]);

  const onSubmit = async (data) => {
    console.log("submit data", data);
    const formData = new FormData();
    formData.append("ai[name]", data.name);
    formData.append("ai[intro]", data.intro);
    if (data.image[0] != undefined) {
      formData.append("ai[image]", data.image[0]);
    }
    formData.append(
      "ai[lastSystemMessage]",
      JSON.stringify(data.lastSystemMessage)
    );
    formData.append("ai[mainCategorySlug]", data.mainCategorySlug);
    formData.append("ai[subCategorySlug]", data.subCategorySlug);
    formData.append("ai[leafCategorySlug]", data.leafCategorySlug);
    formData.append("ai[userMessage]", data.userMessage);
    formData.append("ai[maxTokens]", data.maxTokens);
    formData.append("ai[temperature]", data.temperature);
    formData.append("ai[assist_message]", data.assistMessage);
    formData.append("ai[prompt]", data.prompt);
    formData.append("ai[orderIdx]", data.orderIdx);
    formData.append("ai[status]", data.status);
    if (data.slug != null) {
      formData.append("ai[slug]", data.slug);
    }
    if (props.ai?.id != null) {
      await adminUpdateAi(props.ai.slug, formData);
      toastSuccess("更新しました");
    } else {
      await adminCreateAi(formData);
      toastSuccess("作成しました");
    }
  };
  const toastSuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleFileChange = (event) => {
    setValue("image", event.target.files[0]);
  };

  const onChangeMainCategory = async (v: string) => {
    setSubCategories([]);
    setLeafCategories([]);
    let { data } = await adminsGetSubCategories({
      mainCategorySlug: v,
    });
    setSubCategories(data);
  };

  const onChangeSubCategory = async (v: string) => {
    setLeafCategories([]);
    let { data } = await adminsGetLeafCategories({
      subCategorySlug: v,
    });
    setLeafCategories(data);
  };

  return (
    <>
      <form className="simple_form edit_ai" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="mb-4 string required ai_name">
            <label
              className="block string required text-sm font-medium text-gray-600"
              htmlFor="ai_name"
            >
              名称
              <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                *必須
              </span>
            </label>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 string required"
              type="text"
              name="ai[name]"
              id="ai_name"
              {...register("name", { required: true })}
            />
            {errors.name?.type === "required" && (
              <p className="font-semibold text-sm text-red-500">
                入力してください
              </p>
            )}
          </div>
        </div>
        <div>
          {editImage ? (
            <>
              <div className="mb-4 file required ai_image">
                <label
                  className="text-sm font-medium text-gray-600 block file required text-sm font-medium text-gray-600"
                  htmlFor="ai_image"
                >
                  アイコン画像
                  <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                    *必須
                  </span>
                </label>
                <div className="grid grid-cols-12">
                  <input
                    className="col-span-10 w-full text-gray-500 border rounded mt-1 file required"
                    type="file"
                    name="ai[image]"
                    id="ai_image"
                    onChange={handleFileChange}
                    {...register("image", { required: true })}
                  />
                  <button
                    type="button"
                    className="relative col-span-2 lg:col-span-1 md:col-span-2 rounded px-2 py-1 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => setEditImage(false)}
                  >
                    キャンセル
                  </button>
                </div>
                {errors.image?.type === "required" && (
                  <p className="font-semibold text-sm text-red-500">
                    入力してください
                  </p>
                )}
              </div>
            </>
          ) : (
            <>
              <label
                className="text-sm font-medium text-gray-600 block file required text-sm font-medium text-gray-600"
                htmlFor="ai_image"
              >
                アイコン画像
              </label>
              <div className="flex mt-1 items-center mb-4">
                <img
                  src={props.ai.image.url}
                  className="w-20 mr-2 flex-shrink-0 rounded-full bg-gray-300"
                />
                <button
                  type="button"
                  className="relative lg:col-span-1 md:col-span-2 rounded px-2 py-1 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => setEditImage(true)}
                >
                  変更
                </button>
              </div>
            </>
          )}
        </div>
        <div>
          <div className="mb-4 text optional ai_intro">
            <label
              className="block text optional text-sm font-medium text-gray-600"
              htmlFor="ai_intro"
            >
              説明文
            </label>
            <textarea
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   text optional"
              rows={10}
              name="ai[intro]"
              id="ai_intro"
              {...register("intro")}
            ></textarea>
          </div>
        </div>
        <div>
          <div className="mb-4 select required ai_main_category">
            <label
              className="block select required text-sm font-medium text-gray-600"
              htmlFor="ai_main_category_id"
            >
              大カテゴリ
              <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                *必須
              </span>
            </label>
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select required"
              name="ai[main_category_id]"
              id="ai_main_category_id"
              {...register("mainCategorySlug", { required: true })}
              onChange={(e) => onChangeMainCategory(e.target.value)}
            >
              {props.mainCategories.map((c, i) => {
                return (
                  <option key={`main-category-${i}`} value={c.slug}>
                    {c.name}
                  </option>
                );
              })}
            </select>
            {errors.mainCategorySlug?.type === "required" && (
              <p className="font-semibold text-sm text-red-500">
                入力してください
              </p>
            )}
          </div>
        </div>
        {subCategories.length > 0 && (
          <div>
            <div className="mb-4 select optional ai_sub_category">
              <label
                className="block select optional text-sm font-medium text-gray-600"
                htmlFor="ai_sub_category_id"
              >
                中カテゴリ
              </label>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select optional"
                name="ai[sub_category_id]"
                id="ai_sub_category_id"
                {...register("subCategorySlug")}
                onChange={(e) => onChangeSubCategory(e.target.value)}
              >
                {subCategories.map((c, i) => {
                  return (
                    <option value={c.slug} key={`sub-category-${i}`}>
                      {c.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        )}
        {leafCategories.length > 0 && (
          <div>
            <div className="mb-4 select optional ai_leaf_category">
              <label
                className="block select optional text-sm font-medium text-gray-600"
                htmlFor="ai_leaf_category_id"
              >
                小カテゴリ
              </label>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select optional"
                name="ai[leaf_category_id]"
                id="ai_leaf_category_id"
                {...register("leafCategorySlug")}
              >
                {leafCategories.map((c, i) => {
                  return (
                    <option value={c.slug} key={`leaf-category-${i}`}>
                      {c.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        )}
        <div>
          <div className="p-3 bg-gray-100 border mb-2 rounded-md">
            <h2 className="text-sm">置換ルール</h2>
            <div className="flex flex-wrap">
              {props.aiForms.map((aiForm, i) => {
                return (
                  <div
                    className="text-xs mt-1 text-gray-600 mr-2"
                    key={`ai-form-${i}`}
                  >
                    {aiForm.label}: %{aiForm.inputName}%
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mb-4 text optional ai_assist_message">
            <label
              className="block text optional text-sm font-medium text-gray-600"
              htmlFor="ai_assist_message"
            >
              アシスト文
            </label>
            <textarea
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   text optional"
              rows={3}
              name="ai[assist_message]"
              id="ai_assist_message"
              placeholder="以下の情報を入れてください"
              {...register("assistMessage")}
            ></textarea>
          </div>
          <div className="mb-4 text optional ai_prompt">
            <label
              className="block text optional text-sm font-medium text-gray-600"
              htmlFor="ai_prompt"
            >
              プロンプト
            </label>
            <textarea
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   text optional"
              rows={20}
              name="ai[prompt]"
              id="ai_prompt"
              {...register("prompt")}
            ></textarea>
          </div>
        </div>
        {fields.map((_, i) => {
          return (
            <div
              className="mb-4 text optional ai_user_message"
              key={`last-system-message-${i}`}
            >
              <label
                className="block text optional text-sm font-medium text-gray-600"
                htmlFor="ai_user_message"
              >
                最後のシステムメッセージ - {i + 1}
              </label>
              <div className="grid grid-cols-12 items-top">
                <textarea
                  className="col-span-11 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   text optional"
                  rows={3}
                  name="ai[user_message]"
                  id="ai_user_message"
                  {...register(`lastSystemMessage.${i}`)}
                ></textarea>
                <div className="mt-1 text optional ai_user_message col-span-1">
                  <div className="text-center">
                    <button
                      type="button"
                      className="relative lg:col-span-1 md:col-span-2 rounded px-3 py-2 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={() => remove(i)}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="text-center">
          <button
            type="button"
            className="relative lg:col-span-1 md:col-span-2 rounded px-3 py-2 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => append("")}
          >
            追加
          </button>
        </div>

        <div>
          <div className="mb-4 integer optional ai_order_idx">
            <label
              className="block integer optional text-sm font-medium text-gray-600"
              htmlFor="ai_order_idx"
            >
              並び順
            </label>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   numeric integer optional"
              type="number"
              step="1"
              name="ai[order_idx]"
              id="ai_order_idx"
              {...register(`orderIdx`)}
            />
          </div>
        </div>
        <div>
          <div className="mb-4 enum optional ai_status">
            <label
              className="block enum optional text-sm font-medium text-gray-600"
              htmlFor="ai_status"
            >
              公開ステータス
            </label>
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   enum optional form-control"
              name="ai[status]"
              id="ai_status"
              {...register(`status`)}
            >
              <option value="draft">下書き</option>
              <option value="published">公開済み</option>
            </select>
          </div>
        </div>
        <div>
          <div className="mb-4 integer optional ai_max_tokens">
            <label
              className="block integer optional text-sm font-medium text-gray-600"
              htmlFor="ai_max_tokens"
            >
              Max tokens
            </label>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   numeric integer optional"
              type="number"
              step="1"
              name="ai[max_tokens]"
              id="ai_max_tokens"
              {...register(`maxTokens`)}
            />
          </div>
        </div>
        <div>
          <div className="mb-4 decimal optional ai_temperature">
            <label
              className="block decimal optional text-sm font-medium text-gray-600"
              htmlFor="ai_temperature"
            >
              Temperature
            </label>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   numeric decimal optional"
              type="number"
              step="any"
              name="ai[temperature]"
              id="ai_temperature"
              {...register(`temperature`)}
            />
          </div>
        </div>
        <div>
          <div className="mb-4 string optional ai_slug">
            <label
              className="block string optional text-sm font-medium text-gray-600"
              htmlFor="ai_slug"
            >
              スラグ
            </label>
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   string optional"
              type="text"
              name="ai[slug]"
              id="ai_slug"
              {...register(`slug`)}
            />
          </div>
        </div>
        <input
          type="submit"
          name="commit"
          value="送信する"
          className="mt-4 w-full cursor-pointer text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2 text-center"
        />
      </form>
      <ToastContainer />
    </>
  );
};
export default AdminsAisEdit;
