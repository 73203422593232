import axios from "axios";

const getClient = () => {
  const client = axios.create({
    baseURL: "/api/v1",
  });

  const onSuccess = (response) => response;

  const onError = (err) => {
    return Promise.reject(err);
  };

  client.interceptors.response.use(onSuccess, onError);

  client.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-TOKEN": document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content"),
  };

  return client;
};

export default getClient;
