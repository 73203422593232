import getClient from "../client";

/**
 * サブカテゴリー一覧を取得
 */
export const adminUpdatePrompt = async (slug: string, prompt: string) => {
  return getClient().post(`/admins/ais/${slug}/update_prompt.json`, {
    prompt: prompt,
  });
};

export const adminCreateAi = async (params) => {
  return getClient().post(`/admins/ais.json`, params);
};

export const adminUpdateAi = async (slug: string, params) => {
  return getClient().put(`/admins/ais/${slug}.json`, params);
};
