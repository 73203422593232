import React, { useRef, useMemo, useEffect, useState } from "react";

import { MainCategory } from "../../../interfaces/main_category";
import { SubCategory } from "../../../interfaces/sub_category";
import { LeafCategory } from "../../../interfaces/leaf_category";
import { Ai } from "../../../interfaces/ai";
import CategoryBtn from "../../users/index/CategoryBtn";
import AiCard from "../../users/index/AiCard";
import { usersGetSubCategories } from "../../../libs/api/users/sub_category";
import { usersGetLeafCategories } from "../../../libs/api/users/leaf_category";
import { usersGetAis } from "../../../libs/api/users/ai";
type Props = {
  mainCategories: [MainCategory];
  ais: [Ai];
};

const UsersIndex: React.FC<Props> = (props) => {
  const [selectedMainCategory, setSelectedMainCategory] =
    useState<MainCategory>(null);
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<SubCategory>(null);
  const [selectedLeafCategory, setSelectedLeafCategory] =
    useState<LeafCategory>(null);
  const [subCategories, setSubCategories] = useState([]);
  const [leafCategories, setLeafCategories] = useState([]);
  const [ais, setAis] = useState(props.ais);

  const onSelectMainCategory = async (category) => {
    //子カテゴリはクリア
    setSubCategories([]);
    setLeafCategories([]);
    //選択済みカテゴリ系もクリア
    setSelectedSubCategory(null);
    setSelectedLeafCategory(null);
    //ここで選択
    setSelectedMainCategory(category);
    if (category != null) {
      let { data } = await usersGetSubCategories({
        mainCategorySlug: category?.slug,
      });
      //サブカテゴリ一覧をセット
      setSubCategories(data);
    }
    //AIを再検索
    await searchAi({ mainCategorySlug: category?.slug });
  };

  const onSelectSubCategory = async (category) => {
    //子カテゴリはクリア
    setLeafCategories([]);
    //選択済みカテゴリ系もクリア
    setSelectedLeafCategory(null);
    //ここで選択
    setSelectedSubCategory(category);
    if (category != null) {
      let { data } = await usersGetLeafCategories({
        subCategorySlug: category?.slug,
      });
      //サブカテゴリ一覧をセット
      setLeafCategories(data);
    }
    //AIを再検索
    await searchAi({ subCategorySlug: category?.slug });
  };

  const onSelectLeafCategory = async (category) => {
    setSelectedLeafCategory(category);
    //AIを再検索
    await searchAi({ leafCategorySlug: category?.slug });
  };

  const searchAi = async (customParams = {}) => {
    const params = {
      mainCategorySlug: selectedMainCategory?.slug,
      subCategorySlug: selectedSubCategory?.slug,
      leafCategorySlug: selectedLeafCategory?.slug,
      ...customParams,
    };

    let { data } = await usersGetAis(params);
    setAis(data);
  };

  return (
    <>
      <div className="mt-10">
        <h2 className="text-md font-semibold text-gray-900">
          カテゴリを絞り込む
        </h2>
      </div>
      <div className="flex flex-wrap">
        {props.mainCategories.map((category) => {
          return (
            <CategoryBtn
              key={`main-category-${category.slug}`}
              setSelected={onSelectMainCategory}
              category={category}
              selected={selectedMainCategory}
            />
          );
        })}
      </div>
      {subCategories.length > 0 && (
        <>
          <div className="mt-5">
            <h2 className="text-md font-semibold text-gray-900">
              中カテゴリを絞り込む
            </h2>
          </div>
          <div className="flex flex-wrap">
            {subCategories.map((category) => {
              return (
                <CategoryBtn
                  key={`sub-category-${category.slug}`}
                  setSelected={onSelectSubCategory}
                  category={category}
                  selected={selectedSubCategory}
                />
              );
            })}
          </div>
        </>
      )}
      {leafCategories.length > 0 && (
        <>
          <div className="mt-5">
            <h2 className="text-md font-semibold text-gray-900">
              小カテゴリを絞り込む
            </h2>
          </div>
          <div className="flex flex-wrap">
            {leafCategories.map((category) => {
              return (
                <CategoryBtn
                  key={`leaf-category-${category.slug}`}
                  setSelected={onSelectLeafCategory}
                  category={category}
                  selected={selectedLeafCategory}
                />
              );
            })}
          </div>
        </>
      )}
      {ais.length > 0 && (
        <div className="mt-10">
          <div
            className="grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 lg:grid-cols-2"
            role="list"
          >
            {ais.map((ai) => {
              return <AiCard key={`ai-${ai.slug}`} ai={ai} />;
            })}
          </div>
        </div>
      )}
    </>
  );
};
export default UsersIndex;
