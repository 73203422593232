import React from "react";
import { AiForm } from "../../../../interfaces/ai_form";
import InputText from "./forms/InputText";
import TextArea from "./forms/TextArea";
import InputSelect from "./forms/InputSelect";
import { useForm } from "react-hook-form";
type Props = {
  submit: (data: any) => void;
  aiForms: AiForm[];
  streaming: boolean;
  cancelStreaming: () => void;
};
const UserAiChatPromptSettingForm: React.FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const inputTag = (aiForm, idx) => {
    if (aiForm.kind == "input_text") {
      return (
        <InputText
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
        />
      );
    } else if (aiForm.kind == "text_area") {
      return (
        <TextArea
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
        />
      );
    } else if (aiForm.kind == "input_select") {
      return (
        <InputSelect
          key={`ai_form_${idx}`}
          aiForm={aiForm}
          register={register}
          errors={errors}
        />
      );
    } else if (aiForm.kind == "input_radio") {
    }
  };

  const onSubmit = (data) => {
    props.submit(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="shadow sm:overflow-hidden sm:rounded-md border">
          <div className="space-y-6 bg-white py-6 px-2 sm:p-6">
            <div className="grid lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-6 gap-3">
              {props.aiForms.map((aiForm, idx) => {
                return inputTag(aiForm, idx);
              })}
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
            {props.streaming == true ? (
              <div
                className="inline-flex cursor-pointer justify-center rounded-md bg-blue-300 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={props.cancelStreaming}
              >
                キャンセル
              </div>
            ) : (
              <button
                type="submit"
                className="inline-flex justify-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                生成する
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default UserAiChatPromptSettingForm;
