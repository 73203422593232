import React, { useRef, useMemo, useEffect, useState } from "react";

import { Ai } from "../../../interfaces/ai";

type Props = { ai: Ai };

const UsersAi: React.FC<Props> = (props) => {
  return (
    <>
      <a
        className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
        href={`/users/ais/${props.ai.slug}`}
      >
        <div className="border-gray-200 bg-white px-3 py-2 flex justify-between">
          <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-4">
              <div className="flex items-center flex-wrap text-gray-600 text-base font-semibold space-x-2">
                <div className="flex-shrink-0">
                  <i className={`${props.ai.mainCategoryFontawesomeClass}`}></i>
                </div>
                <span>{props.ai.mainCategoryName}</span>
                {props.ai?.subCategoryName != null && (
                  <>
                    <div className="select-none text-slate-400">/</div>
                    <span>{props.ai?.subCategoryName}</span>
                  </>
                )}
                {props.ai?.leafCategoryName != null && (
                  <>
                    <div className="select-none text-slate-400">/</div>
                    <span>{props.ai?.leafCategoryName}</span>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="items-center">
            <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">
              {props.ai.generateCount}
            </span>
          </div>
        </div>
        <div className="flex w-full items-center justify-between space-x-4 p-3">
          <div className="flex-1">
            <div className="items-center space-x-3">
              <h3 className="text-base text-gray-900 font-semibold">
                {props.ai.name}
              </h3>
            </div>
            <p className="mt-1 text-xs text-gray-500">{props.ai.intro}</p>
          </div>
          <img
            alt=""
            className="object-cover h-24 w-24 flex-shrink-0 rounded-full bg-gray-300"
            src={props.ai.image.thumb.url}
          />
        </div>
      </a>
    </>
  );
};
export default UsersAi;
