import React, { useRef, useMemo, useEffect, useState } from "react";

import { MainCategory } from "../../../interfaces/main_category";
import { SubCategory } from "../../../interfaces/sub_category";
import { LeafCategory } from "../../../interfaces/leaf_category";

type Props = {
  setSelected: (category: MainCategory | SubCategory | LeafCategory) => void;
  selected: MainCategory | SubCategory | LeafCategory;
  category: MainCategory | SubCategory | LeafCategory;
};

const UsersCategoryBtn: React.FC<Props> = (props) => {
  if (props.category.aiCount == 0) {
    return;
  }
  const isSelected = () => {
    return props.selected?.slug == props.category.slug;
  };
  const btnClassName = () => {
    if (isSelected()) {
      return "rounded-md bg-blue-700 py-2 px-5 text-sm font-semibold text-white shadow-sm hover:bg-blue-60 flex items-center flex-wrap mr-3 mt-2";
    } else {
      return "rounded-md bg-white py-2 px-5 text-sm font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center flex-wrap mr-3 mt-2";
    }
  };
  const badgeClassName = () => {
    if (isSelected()) {
      return "inline-flex items-center justify-center w-5 h-5 ml-2 text-xs font-semibold text-blue-800 bg-white rounded-full";
    } else {
      return "inline-flex items-center justify-center w-5 h-5 ml-2 text-xs font-semibold text-gray-800 bg-gray-200 rounded-full";
    }
  };
  const toggleSelected = () => {
    if (isSelected()) {
      props.setSelected(null);
    } else {
      props.setSelected(props.category);
    }
  };

  return (
    <>
      <button
        className={btnClassName()}
        type="button"
        onClick={() => toggleSelected()}
      >
        {props.category.fontawesomeClass != null && (
          <span className="mr-2">
            <i className={`${props.category.fontawesomeClass}`}></i>
          </span>
        )}
        <span>{props.category.name}</span>
        {props.category.aiCount != null && (
          <span className={badgeClassName()}>{props.category.aiCount}</span>
        )}
      </button>
    </>
  );
};
export default UsersCategoryBtn;
