import React from 'react'
// import ReactDOM from 'react-dom'
import { useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
// import { useInView } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Container } from './Container'
const reviews = [
  {
    title: '1日1つの記事を書いていたのが8つになりました。',
    body: '書きたいキーワードを入れるだけで記事がすぐに生成されるのでほとんど手直しなしで投稿できます。',
    author: '',
    rating: 5
  },
  {
    title: '私はずっと優れたAIライティングツールを研究してきました。そしてついにオーダーメイドAIを見つけました。',
    body: 'このAIライティングアシスタントは、ブログ投稿、ランディング ページ、Google 広告、Facebook 広告、商品説明などを作成できます。すべてのフリーランサーにとって必須のツールです。',
    author: '',
    rating: 5
  },
  {
    title: '言葉をまとめるのに苦労していましたが、オーダーメイドAIは私の救世主です!',
    body: 'クリエイティブなアイデアを考えるのに苦労していたときに、このツールを見つけました。メールを書いたり、アイデアを生み出したりする素晴らしいツールです。',
    author: '',
    rating: 5
  },
  {
    title: 'このツールがあれば、Youtubeコンテンツ制作がにとっても楽になります！',
    body: '私はYoutubeチャンネルを複数運営していて、動画の説明文やタイトル、スクリプトの作成に携わっていますが、このオーダーメイドAIがあれば、シナリオ作成がぐっと楽になります。キーワードを入力するだけで、すぐに記事を生成することができます。自然な文章が自動生成されるため、手直しも少なく、スムーズに記事を作成することができるようになりました。',
    author: '',
    rating: 5
  },
  {
    title: '業務効率が大幅にUPしましました！',
    body: 'このオーダーメイドAIのおかげで、業務効率が大幅にUPしました。商品説明や広告文を自動生成するだけでなく、キャッチフレーズやキーワードの提案もしてくれるため、クオリティも高く、手間も省けます。マルチチャネルでのプロモーションも容易になるため、マーケターにとっては必須のツールです！',
    author: '',
    rating: 5
  },
  {
    title: '記事作成がスムーズになりました',
    body: 'キーワードを入力するだけで、自動的に文章が生成されるため、手間が省けます。クオリティも高く、自然な文章を生み出すため、手直しも少なくて済みます。',
    author: '',
    rating: 5
  },
  {
    title: 'コピーライターの仕事が増えました',
    body: '以前はクライアントの要望に沿って、文章を書いていましたが、オーダーメイドAIを使うようになってから、仕事が増えました。キーワードを入力するだけで、素早くクオリティの高い文章を素早く作成できるため、クライアントからの評価も上がりました。',
    author: '',
    rating: 5
  },
  {
    title: '商品説明が簡単になりました',
    body: '商品説明を作成するのが大変で、時間がかかっていましたが、AIライティングツールを使うようになってから、簡単に商品説明を作成することができるようになりました。キーワードを入力するだけで、自然な文章が生成されるため、手直しが必要なくなりました。',
    author: '',
    rating: 5
  },
  {
    title: '小説のストーリー作りが楽になりました',
    body: '小説のストーリーを考えることに苦労していましたが、オーダーメイドAIを使うようになってから、ストーリー作りが楽になりました。自動生成された文章をベースに、自分なりのアイデアを加えることで、素晴らしいストーリーができあがります。',
    author: '',
    rating: 5
  },
  {
    title: 'ブランドイメージを向上させました',
    body: 'キーワードを入力するだけで、簡単に素晴らしいキャッチフレーズを作成できるため、ブランドのイメージ向上につながりました。',
    author: '',
    rating: 5
  }
]

function StarIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 20 20" aria-hidden="true" {...props}>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    </svg>
  )
}

function StarRating({ rating }) {
  return <div className="flex">{[...Array(5).keys()].map(index => <StarIcon key={index} className={clsx('h-5 w-5', rating > index ? 'fill-pink-500' : 'fill-gray-300')} />)}</div>
}

function Review({ title, body, author, rating, className, ...props }) {
  let animationDelay = useMemo(() => {
    let possibleAnimationDelays = ['0s', '0.1s', '0.2s', '0.3s', '0.4s', '0.5s']
    return possibleAnimationDelays[Math.floor(Math.random() * possibleAnimationDelays.length)]
  }, [])

  return (
    <figure
      className={clsx('rounded-3xl bg-white p-6 shadow-md shadow-gray-900/5', className)}
      style={{
        animationName: 'fade-in',
        animationDelay,
        animationDuration: '0.5s',
        animationTimingFunction: 'ease-in-out',
        animationFillMode: 'forwards'
      }}
      {...props}
    >
      <blockquote className="text-gray-900">
        <StarRating rating={rating} />
        <p className="mt-4 text-base font-semibold leading-6 before:content-[''] after:content-['']">{title}</p>
        <p className="mt-3 text-sm leading-7">{body}</p>
      </blockquote>
      {/* <figcaption className="mt-3 text-sm text-gray-600 before:content-['-']">{author}</figcaption> */}
    </figure>
  )
}

console.log(Review)





function splitArray(array: string | any[], numParts: number) {
  let result = []
  for (let i = 0; i < array.length; i++) {
    let index = i % numParts
    if (!result[index]) {
      result[index] = []
    }
    result[index].push(array[i])
  }
  return result
}

function ReviewColumn({ className, reviews, reviewClassName = undefined, msPerPixel = 0 }) {
  let columnRef = useRef<HTMLDivElement>(null)
  let [columnHeight, setColumnHeight] = useState<number>(0)
  let duration = `${columnHeight * msPerPixel}ms`
  useEffect(() => {
    let resizeObserver = new window.ResizeObserver(() => {
      if (columnRef.current) {
        setColumnHeight(columnRef.current.offsetHeight)
      }
    })
    if (columnRef.current) {
      resizeObserver.observe(columnRef.current)
    }
    return () => {
      resizeObserver.disconnect()
    }
  }, [])
  return (
    <div ref={columnRef} className={clsx('animate-marquee space-y-8 py-4', className)} style={{ '--marquee-duration': duration }}>
      {reviews.concat(reviews).map((review: JSX.IntrinsicAttributes & { [x: string]: any; title: any; body: any; author: any; rating: any; className: any }, reviewIndex: number) => (
        <Review
          key={reviewIndex}
          aria-hidden={reviewIndex >= reviews.length}
          className={reviewClassName ? reviewClassName(reviewIndex % reviews.length) : ''}
          {...review}
        />
      ))}
    </div>
  )
}






function ReviewGrid() {
  const [containerRef, isInView] = useInView({ threshold: 0.4, rootMargin: '0px 0px -20% 0px', triggerOnce: true })
  let columns = splitArray(reviews, 3)
  columns = [columns[0], columns[1], splitArray(columns[2], 2)]

  return (
    <div ref={containerRef} className="relative -mx-4 mt-16 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 sm:mt-20 md:grid-cols-2 lg:grid-cols-3">
      {isInView && (
        <>
          <ReviewColumn reviews={[...columns[0], ...columns[2].flat(), ...columns[1]]} reviewClassName={(reviewIndex: number) => clsx(reviewIndex >= columns[0].length + columns[2][0].length && 'md:hidden', reviewIndex >= columns[0].length && 'lg:hidden')} msPerPixel={10} className={undefined} />
          <ReviewColumn reviews={[...columns[1], ...columns[2][1]]} className="hidden md:block" reviewClassName={(reviewIndex: number) => clsx(reviewIndex >= columns[1].length && 'lg:hidden')} msPerPixel={15} />
          <ReviewColumn reviews={columns[2].flat()} className="hidden lg:block" msPerPixel={10} />
        </>
      )}
      <div className="pointer-events-none absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900" />
      <div className="pointer-events-none absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-gray-900" />
    </div>
  )
}





const Reviews = () => {
  return (
    <section id="reviews" aria-labelledby="reviews-title" className="pt-20 pb-16 sm:pt-56 sm:pb-24 md:pt-36">
      <Container className={undefined}>
        <div className="mx-auto max-w-4xl text-center">
          <p className="rp_text_30rem mt-2 text-4xl font-bold tracking-wider text-white sm:text-6xl">利用者の声</p>
        </div>
        <p className="sp_pl_1rem text_grade font-bold tracking-wide mx-auto mt-6 max-w-full text-center text-2xl leading-8 text-white">
          あなたの要望に応える、<br className='sp_br' />頼もしいメイドちゃんたち。</p>
        <ReviewGrid />
      </Container>
    </section>
  )
}


export default Reviews


