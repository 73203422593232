import React, { useEffect, useState } from "react";

const EventSourceDemo = () => {
  const [messages, setMessages] = useState([]);
  
  useEffect(() => {
    const source = new EventSource("/users/event_source");
    source.onmessage = (event) => {
      console.log("event", event)
      const data = event.data;
      setMessages((prevMessages) => [...prevMessages, data]);
    };

  }, []);

  return (
    <div>
      <h1>Real-time updates</h1>
      <ul>
        {messages.map((message, index) => (
          <li key={index}>{message}</li>
        ))}
      </ul>
    </div>
  );
};

export default EventSourceDemo;
