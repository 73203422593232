import getClient from "../client";
import { GetAisParams, PostAiChatGpt } from "../../../interfaces/requests/ai";

/**
 * サブカテゴリー一覧を取得
 */
export const usersGetAis = async (params: GetAisParams) => {
  return getClient().get(`/users/ais.json`, {
    params: params,
  });
};

/**
 * chatGPTとchat
 */
export const usersPostAiChatGpt = async (params: PostAiChatGpt) => {
  return getClient().post(`/users/ais/chat_gpt.json`, params);
};
