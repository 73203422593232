import getClient from "../client";
import { GetLeafCategoriesParams } from "../../../interfaces/requests/leaf_category";

/**
 * サブカテゴリー一覧を取得
 */
export const adminsGetLeafCategories = async (
  params: GetLeafCategoriesParams
) => {
  return getClient().get(`/admins/leaf_categories.json`, {
    params: params,
  });
};
