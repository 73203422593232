import React, { useRef, useMemo, useEffect, useState } from "react";
import { Ai } from "../../../../interfaces/ai";
type Props = {
  ai: Ai;
  setPropmt: (prompt: string) => void;
};

const AdminsAisFormPrompt: React.FC<Props> = (props) => {
  return (
    <>
      <label
        htmlFor="first-name"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        プロンプト
      </label>
      <div className="mt-1">
        <textarea
          id="prompt"
          name="prompt"
          className="block w-full rounded-md border-0 py-2 pl-3 pr-10 text-gray-900 ring-1 ring-inset text-sm ring-gray-300 focus:ring-2 focus:ring-indigo-600"
          onChange={(e) => props.setPropmt(e.target.value)}
          defaultValue={props.ai.prompt}
          rows={50}
          style={{ height: "auto" }}
        ></textarea>
      </div>
    </>
  );
};
export default AdminsAisFormPrompt;
